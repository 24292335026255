import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Styled from "styled-components"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import RuledBreak from "../../components/RuledBreak"
import { ButtonTrace } from "../../components/Button"

const About = ({ data }) => {
  const header = data.header.edges[0].node.childImageSharp.fluid
  const matt = data.matt.edges[0].node.childImageSharp.fluid
  return (
    <Layout fluid={header} alt="About Header" height={600} heightMobile={300}>
      <SEO
        title="About"
        keywords={["About", "Beyond The Apprenticeship", "Matt Pehrson"]}
      />
      <div className="row">
        <div className="col-md-12 py-3">
          <RuledBreak>About</RuledBreak>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 py-3">
          <Img fluid={matt} alt="Matt Pehrson" />
        </div>
        <div className="col-md-6 py-3">
          <p className="pb-2">
            Welcome to Beyond the Apprenticeship. My name is Matt Pehrson, I’m a
            tattoo artist and the owner of The Zion Tattoo Company in St George
            Utah. I’ve been tattooing for over 10 years, I’ve owned multiple
            studios, and I’ve tattooed all over the world.
          </p>
          <p className="py-2">
            I come from a Military background, having spent 9 years primarily in
            education and training in the US Air Force, then transitioning into
            my career as tattoo artist and business owner.
          </p>
          <p className="pt-2">
            After spending over a decade in the tattoo business, I’ve seen a lot
            of artists with varying levels of success. I’ve noticed that this
            had less to do with the quality of the tattoos being produced, and
            more to do with client interactions and work effeciency. Some people
            don’t want to believe this but it is a simple truth. Being good at
            applying a tattoo, is not enough to make you a successful tattoo
            artist. I decided to put together a program to assist tattoo artists
            in becoming more successful in these areas, in turn, making them far
            more successful the industry.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-center py-3">
          <ButtonTrace to="/education" btn={3}>
            Learn More
          </ButtonTrace>
        </div>
      </div>

      <div className="row">
        <div className="col-md-10 mr-auto ml-auto text-center py-3">
          <p>
            I have a background in tattooing, business, finance, management, and
            education. Most importantly, I have spent over 10 years making most
            of the mistakes people are going to make in this industry, and I can
            help artists avoid the pitfalls that so many of us had to stumble
            through.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-10 mr-auto ml-auto text-center py-3">
          <p>
            I believe that I can provide you with the tools to become more
            efficient, productive, and successful.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-10 mr-auto ml-auto text-center py-3">
          <p>
            I am completely accessible. Contact me through email to set up a
            phone call, and I would love to explain the program and why it can
            help you.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-center py-3">
          <ButtonTrace to="/contact" btn={3}>
            Contact Me
          </ButtonTrace>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AboutQuery {
    header: allFile(filter: { name: { eq: "headerAbout_1300x682" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1300) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
    matt: allFile(filter: { name: { eq: "matt_600x600" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default About
